<template>
  <Layout :navigation="navigationObj">
    <router-view />
  </Layout>
</template>

<script>
import Layout from "../components/Layout";

export default {
  name: "Deposit",
  data() {
    return {
      navigationObj: {
        items: [
          {
            title: "Create deposit organization",
            name: "create-deposit-organization",
            routeName: "create-deposit-organization",
          },
          {
            title: "Deposit Organizations",
            name: "deposit-organizations",
            routeName: "deposit-organizations",
          }
        ],
      },
    };
  },
  components: {
    Layout,
  },
};
</script>

<style scoped>
</style>
